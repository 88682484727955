<template>
  <div class="container" :style="{ height: height }">
    <ul class="product-wapper">
      <li
          class="product-item"
          v-for="(item, index) in list"

          :key="item.id"
          :class="active == item.categoryCode ? 'active' : ''"
      >
        <img @click="onClick(item,index)"  :src="item.switch === true ?
          'https://weiyeglobal.oss-accelerate.aliyuncs.com/News/bfd4598252a263baab4105e6e0392ee2.png'
          : 'https://weiyeglobal.oss-accelerate.aliyuncs.com/News/152978b11fabb3e9ca86294e60b63eed.png'" alt="">
       <spna style="margin-left: 5px;vertical-align: middle"  @click="onSecondOne(item.categoryCode)"> {{ item.categoryEnname }}</spna>
        <ul class="second-class-wapper" :class="item.switch === true?'product-show':'product-hide'" >
          <li
              class="second-class-item"
              v-for="item in children"
              :key="item.id"
              @click="onSecond(item.categoryCode)"
          >
            <span class="ly_bncc_dian">&bull;</span> {{ item.categoryEnname }}
          </li>
        </ul>
      </li>
    </ul>

  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import $ from "jquery";
export default defineComponent({
  name: "ProCateList",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    children: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["handleclick", "handlesecclick"],
  setup(props, ctx) {
    const height = ref("0px");
    onMounted(() => {
      height.value =
          Number($(window).height()) -
          Number($("header").height()) -
          Number($(".home-wapper .title").height()) -
          Number($("footer").height()) +
          "px";
    });
    const active = ref(0);
    const currentList = ref();

    watch(
        () => {
          return props.list;
        },
        (list) => {
          // active.value = (list as any)[0].categoryCode;
          currentList.value = props.list;
        }
    );

    function onClick(item: any,index: number) {
      const  indexOne = Number(index);
      active.value = Number(item.categoryCode);
      if(currentList.value[indexOne].switch == true){
        currentList.value[indexOne].switch = false;
      }else{
        currentList.value[indexOne].switch = true;
      }
      currentList.value.forEach((item: any,index: number) => {
        if(index != indexOne){
          item.switch = false
        }
      })
        ctx.emit("handleclick", item.categoryCode);
    }
    function onSecond(classid: string) {
      let type = 2;
      if (!classid) {
        classid = String(active.value);
        type = 0;
      }
      ctx.emit("handlesecclick", classid, type);
    }

    function onSecondOne(nclassid: string) {
      let type = 0;
      if (!nclassid) {
        nclassid = String(active.value);
        type = 0;
      }
      ctx.emit("handlesecclick", nclassid, type);
    }

    return {
      height,
      onClick,
      onSecond,
      onSecondOne,
      active,
    };
  },
});
</script>


<style lang="scss" scoped>

.container {
  width: 100%;
  padding-left: 7em;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  padding-bottom: 20px;

  .product-wapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 98%;
    height: 100%;
    font-size: 14px;
    overflow-y: auto;
    padding-bottom: 40px;
    box-sizing: border-box;
    padding-left: 10px;
    img{
      width:20px;
      vertical-align: middle;
      margin-right: 4px
    }
    .product-item {
      width: 100%;
      padding-left: 7px;
      box-sizing: border-box;
      line-height: 50px;
      overflow: hidden;
      color: #656565;
      text-overflow: ellipsis;
      white-space: nowrap;
      .product-hide{
        display: none;
        overflow: hidden;
        transition: max-height 0.3s; /** 实现动画效果 **/
      }
      .product-show{
        display: block;
        overflow: hidden;
        transition: max-height 1.6s; /** 实现动画效果 **/
      }
    }
    .active {
      color: #df0024;
      //border-left: 3px solid;
      //background-color: #fff;
    }
  }
  .second-class-wapper {
    width: 100%;
    padding: 0 15px;
    height: 100%;
    overflow-y: auto;
    background-color: #f5f5f5;
    .second-class-item {
      width: 48%;
      float:left;
      padding-left: 7px;
      box-sizing: border-box;
      line-height: 30px;
      overflow: hidden;
      color: #262626;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
  }
}
</style>