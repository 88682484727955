
import { defineComponent, onMounted, ref, watch } from "vue";
import $ from "jquery";
export default defineComponent({
  name: "ProCateList",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    children: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["handleclick", "handlesecclick"],
  setup(props, ctx) {
    const height = ref("0px");
    onMounted(() => {
      height.value =
          Number($(window).height()) -
          Number($("header").height()) -
          Number($(".home-wapper .title").height()) -
          Number($("footer").height()) +
          "px";
    });
    const active = ref(0);
    const currentList = ref();

    watch(
        () => {
          return props.list;
        },
        (list) => {
          // active.value = (list as any)[0].categoryCode;
          currentList.value = props.list;
        }
    );

    function onClick(item: any,index: number) {
      const  indexOne = Number(index);
      active.value = Number(item.categoryCode);
      if(currentList.value[indexOne].switch == true){
        currentList.value[indexOne].switch = false;
      }else{
        currentList.value[indexOne].switch = true;
      }
      currentList.value.forEach((item: any,index: number) => {
        if(index != indexOne){
          item.switch = false
        }
      })
        ctx.emit("handleclick", item.categoryCode);
    }
    function onSecond(classid: string) {
      let type = 2;
      if (!classid) {
        classid = String(active.value);
        type = 0;
      }
      ctx.emit("handlesecclick", classid, type);
    }

    function onSecondOne(nclassid: string) {
      let type = 0;
      if (!nclassid) {
        nclassid = String(active.value);
        type = 0;
      }
      ctx.emit("handlesecclick", nclassid, type);
    }

    return {
      height,
      onClick,
      onSecond,
      onSecondOne,
      active,
    };
  },
});
